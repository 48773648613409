import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PolicyBanner from "../components/PolicyBanner";
import HomeIcons from "../components/HomeIcons";
import HomeMan from "../components/HomeMan";
import HomeClients from "../components/HomeClients";

const icons = [
  {
    id: "icon-11",
    src: "images/ac-icon-11.svg",
    title: "No hidden costs",
    desc: "Transparent pricing, with no additional or hidden charges.",
  },
  {
    id: "icon-12",
    src: "images/ac-icon-12.svg",
    title: "Secure platform",
    desc: "Advanced security measures to ensure the safety of your data.",
  },
  {
    id: "icon-13",
    src: "images/ac-icon-13.svg",
    title: "Cover just for you",
    desc: "Customizable coverage options tailored to meet your unique needs.",
  },
];

const protects = [
  {
    id: "icon-31",
    src: "images/ac-icon-31.svg",
    title: "Capital gains tax",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra, elit nec egestas.",
  },
  {
    id: "icon-32",
    src: "images/ac-icon-32.svg",
    title: "Income tax",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra, elit nec egestas.",
  },
  {
    id: "icon-33",
    src: "images/ac-icon-33.svg",
    title: "Land tax",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra, elit nec egestas.",
  },
  {
    id: "icon-34",
    src: "images/ac-icon-34.svg",
    title: "Payroll tax",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra, elit nec egestas.",
  },
  {
    id: "icon-35",
    src: "images/ac-icon-35.svg",
    title: "Workers compensation",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra, elit nec egestas.",
  },
  {
    id: "icon-36",
    src: "images/ac-icon-36.svg",
    title: "BAS/GST compliance",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra, elit nec egestas.",
  },
  {
    id: "icon-37",
    src: "images/ac-icon-37.svg",
    title: "Superannuation guarantee",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra, elit nec egestas.",
  },
  {
    id: "icon-38",
    src: "images/ac-icon-38.svg",
    title: "Fringe benefits tax",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra, elit nec egestas.",
  },
  {
    id: "icon-39",
    src: "images/ac-icon-39.svg",
    title: "Stamp duty",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra, elit nec egestas.",
  },
];

const PolicyHoldersPage = ({ data }) => {
  const { banner, testimonials } = data;
  return (
    <Layout
      bg="policy-holders-bg"
      banner={
        <PolicyBanner
          subheader={banner.frontmatter.subheader}
          header={banner.frontmatter.header}
          header2={banner.frontmatter.header2}
          ctalinktext={banner.frontmatter.ctalinktext}
          socialproof={banner.frontmatter.socialproof}
        />
      }
    >
      <HomeIcons data={icons} />
      <HomeMan
        title="Smarter tax audit insurance will save you time, money and the hassle."
        desc="AuditCover insurance covers  your accountants fees in responding to an audit, reducing stress and keeping more money in your pocket when you need it most."
        hasBottomRadius
        hasButton
        buttonLabel="Get a quote"
        buttonLink="/forms/contact-us/"
      />
      <HomeIcons
        data={protects}
        hasTitle
        hasDescription={false}
        title="Just some of the things we can protect you from..."
        showButton
        buttonLabel="Read our FAQs"
        buttonLink="/faqs/"
      />
      <HomeClients data={testimonials?.edges} isDark />
    </Layout>
  );
};

export default PolicyHoldersPage;

export const contentQuery = graphql`
  query {
    banner: markdownRemark(
      frontmatter: { templateKey: { eq: "content-banner" } }
    ) {
      id
      frontmatter {
        subheader
        header
        header2
        ctalinktext
        socialproof
      }
    }
    testimonials: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "testimonials" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            photo {
              publicURL
            }
            person
            company
            type
            title
            contents
          }
        }
      }
    }
  }
`;
