import React from "react";
import PropTypes from "prop-types";
import { withPrefix } from "gatsby";

const PolicyBanner = () => {
  return (
    <div className="banner">
      <div className="content nu-alt2-c">
        <div className="banner-wr" style={{ alignItems: "flex-end" }}>
          <div className="banner-txt text-white nu-c">
            <h1>
              Tax audit insurance without the <em>icky</em> bits
            </h1>
            <p className="banner-p text-white">
              Protects your tax return from unexpected tax audit costs.
            </p>
            <div className="banner-btn-group">
              <a
                href="/forms/contact-us/"
                className="cta cta-top long w-button cta-top-mirror"
              >
                Get a quote
              </a>
            </div>
          </div>
          <div className="banner-img">
            <img
              src={withPrefix("images/hero-ph.png")}
              alt="Tax audit insurance made easy"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PolicyBanner.propTypes = {
  subheader: PropTypes.string,
  header: PropTypes.string,
  header2: PropTypes.string,
  ctalinktext: PropTypes.string,
  socialproof: PropTypes.string,
};

PolicyBanner.defaultProps = {
  subheader: "Tax audit insurance made easy",
  header: "Give your clients the tax audit protection they need",
  header2: "and reduce the workload for your practice.",
  ctalinktext: "Book  your obligation-free consultation",
  socialproof: "TAX AGENTS AND ACCOUNTANTS USING AUDITCOVER:",
};

export default PolicyBanner;
